import ArrowUp from "../../assets/FormIcons/arrow_up.svg";
import ArrowDown from "../../assets/FormIcons/arrow_down.svg";
import Comments from "../../assets/FormIcons/comments-icon.svg";
import Calender from "../../assets/FormIcons/calendar.svg";
import Clock from "../../assets/FormIcons/clock.svg";

import ToggleForm from "../ToggleForm/ToggleForm";
import InputContainer from "../../UI/InputContainer/InputContainer";
import { StatesCtx } from "../../contexts/StatesProvider";

import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import FromTo, { CityOption } from "../FromTo/FromTo";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Switch } from "@mui/material";

export const MainFormContainer = styled.div<{
  $isMobile: boolean;
  $isTablet: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  margin: ${(props) =>
    props.$isMobile || props.$isTablet ? "20px auto" : "40px auto"};
  margin-top: 16px;
  padding: ${(props) => (props.$isTablet ? "15px" : "20px")};
  gap: 24px;
  justify-self: center;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 35px;
  background-color: #f2f2f2;
  box-shadow: -3px 4px 11px 0px rgba(171, 171, 171, 0.1),
    -12px 15px 20px 0px rgba(171, 171, 171, 0.09),
    -27px 35px 26px 0px rgba(171, 171, 171, 0.05),
    -48px 62px 31px 0px rgba(171, 171, 171, 0.01),
    -75px 97px 34px 0px rgba(171, 171, 171, 0);

  @media (max-width: 620px) {
    width: 450px;
  }

  @media (max-width: 540px) {
    width: 380px;
  }

  @media (max-width: 460px) {
    width: 290px;
  }

  @media (max-width: 380px) {
    width: 240px;
  }
`;

const Input = styled.input<{ $heb: boolean; $comments?: boolean }>`
  display: flex;
  height: ${(props) => (props.$comments ? "150px" : "")};
  width: 100%;
  padding: 8px 17px 8px 10px;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: #fafafa;
  text-align: ${(props) => (props.$heb ? "end" : "")};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a0a0a0;
    opacity: 1;
  }
`;

const RegTripDiv = styled.div<{ $heb: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$heb ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
`;

const RegTripPara = styled.p<{ $heb: boolean }>`
  color: #898989;
  text-align: ${(props) => (props.$heb ? "end" : "")};
`;

const RgTripDaysDiv = styled.div<{ $heb: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$heb ? "row-reverse" : "row")};
`;

const DayBtn = styled.button<{ $isSelected?: boolean }>`
  margin: auto;
  color: #898989;
  border: none;
  font-weight: ${(props) => (props.$isSelected ? "bold" : "normal")};
  border-bottom: ${(props) =>
    props.$isSelected ? "2px solid #898989" : "none"};
`;

const DoubleInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;

  div {
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;

export const SubmitButton = styled.button<{ $driver: boolean }>`
  display: flex;
  width: 100%;
  margin: 4px;
  margin-top: 16px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  background-color: ${(props) => (props.$driver ? "#6663F5" : "#FF9D9D")};
`;

const CancelButton = styled.button<{ $driver: boolean }>`
  display: flex;
  width: 100%;
  margin: 4px;
  margin-top: 16px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 20px;
  border-color: ${(props) => (props.$driver ? "#6663F5" : "#FF9D9D")};
  color: ${(props) => (props.$driver ? "#6663F5" : "#FF9D9D")};
`;

const ErrorSpan = styled.span`
  color: red;
  text-align: center;
`;

const StyleSX = {
  "& .MuiIconButton-root.MuiPickersCalendarHeader-iconButton": {
    order: -1,
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-root": {
    padding: 0,
    width: 0.95,
    margin: 0,
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .MuiInputBase-input": {
    padding: 0,
    width: 0.85,
  },
  "& .MuiInputAdornment-root": {
    width: "fit-content",
    margin: 0,
  },
  "& .MuiSvgIcon-root": {
    width: 18,
    height: 16,
  },
  backgroundColor: "#fafafa",
  padding: 0.5,
};

const AllInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const StyledSwitch = styled(Switch)<{ $ride: boolean }>`
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => (props.$ride ? "#6663F5" : "#FF9D9D")};
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////

const schema = Yup.object().shape({
  from: Yup.string().required("From field is required"),
  to: Yup.string().required("To field is required"),
  date: Yup.string().required("Date field is required"),
  time: Yup.string().required("Time field is required"),
  comments: Yup.string(),
});

type FormValues = {
  from: string;
  to: string;
  date: string;
  time: string;
  comments: string;
};

interface DecodedToken {
  id: string;
}

const MainForm: React.FC<{
  fromOptions: CityOption[];
  toOptions: CityOption[];
}> = ({ fromOptions, toOptions }) => {
  const dimensions = useContext(DimensionsCtx);
  const ctx = useContext(StatesCtx);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    resolver: yupResolver(schema) as Resolver<FormValues, any>,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  useEffect(() => {
    ctx?.fromCitiesFetchData();
    ctx?.toCitiesFetchData();
  }, []);

  if (!ctx) {
    console.log("No Ctx!");
    return null;
  }

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const CalenderIcon = () => {
    return <img src={Calender} />;
  };
  const ClockIcon = () => {
    return <img src={Clock} />;
  };

  const getUserIdFromToken = (): string | null => {
    const token = localStorage.getItem("token");
    if (!token) return null;

    try {
      const decoded: DecodedToken = jwtDecode(token);
      return decoded.id;
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSwitchChecked(isChecked);

    if (!isChecked) {
      setSelectedDays([]);
    }
  };

  const handleDayClick = (day: string) => {
    setSelectedDays((currentSelectedDays) => {
      if (currentSelectedDays.includes(day)) {
        return currentSelectedDays.filter((d) => d !== day);
      } else {
        return [...currentSelectedDays, day];
      }
    });
  };

  const onSubmit = async (data: FormValues) => {
    const payload = {
      ...data,
      userId: getUserIdFromToken(),
      regularTrip: switchChecked,
      days: switchChecked ? selectedDays : [],
    };

    const endpoint =
      ctx.selectOpt === "/rides"
        ? "/api/rides/publish"
        : "/api/passengers/publish";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Success");
      } else {
        console.error("Failed to publish");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = () => {
    ctx.setPublish(false);
    ctx.setSearch(false);
    navigate(-1);
    form.reset();
  };

  return (
    <MainFormContainer
      $isMobile={dimensions.isMobile}
      $isTablet={dimensions.isTablet}
    >
      <ToggleForm />
      <form onSubmit={handleSubmit(onSubmit)}>
        <AllInputs>
          <InputContainer needIcon icon={ArrowUp} comment={false}>
            <Controller
              name="from"
              control={control}
              render={({ field: { onChange, value } }) => {
                const cityOptionValue =
                  fromOptions.find((option) => option.city === value) || null;

                return (
                  <>
                    <FromTo
                      role={t("mainForm.fromHolder")}
                      fromOptions={ctx.fromData}
                      toOptions={ctx.toData}
                      onChange={(option) => onChange(option ? option.city : "")}
                      value={cityOptionValue}
                    />
                  </>
                );
              }}
            />
          </InputContainer>
          {errors.from && <ErrorSpan>{errors.from.message}</ErrorSpan>}
          <InputContainer needIcon icon={ArrowDown} comment={false}>
            <Controller
              name="to"
              control={control}
              render={({ field: { onChange, value } }) => {
                const cityOptionValue =
                  toOptions.find((option) => option.city === value) || null;

                return (
                  <FromTo
                    role={t("mainForm.toHolder")}
                    fromOptions={ctx.fromData}
                    toOptions={ctx.toData}
                    onChange={(option) => onChange(option ? option.city : "")}
                    value={cityOptionValue}
                  />
                );
              }}
            />
          </InputContainer>
          {errors.to && <ErrorSpan>{errors.to.message}</ErrorSpan>}
          <DoubleInput>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputContainer
                needIcon={
                  dimensions.isMobile || dimensions.isTablet ? true : false
                }
                icon={Clock}
                comment={false}
              >
                <Controller
                  name="time"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      localeText={{}}
                      sx={StyleSX}
                      slots={{ openPickerIcon: ClockIcon }}
                      value={value}
                      ampm={false}
                      onChange={(time) => {
                        const formattedTime = dayjs(time).format("hh:mm");
                        onChange(formattedTime);
                      }}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          placeholder: `${t("mainForm.timeHolder")}`,
                        },
                        inputAdornment: {
                          position:
                            dimensions?.language === "he" ? "end" : "start",
                          sx: { p: 1 },
                        },
                      }}
                    />
                  )}
                />
              </InputContainer>
              {/* {errors.time && <ErrorSpan>{errors.time.message}</ErrorSpan>} */}
              <InputContainer
                needIcon={
                  dimensions.isMobile || dimensions.isTablet ? true : false
                }
                icon={Calender}
                comment={false}
              >
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      sx={StyleSX}
                      slots={{ openPickerIcon: CalenderIcon }}
                      value={value ? dayjs(value) : null}
                      onChange={(newDate) => {
                        onChange(
                          newDate ? dayjs(newDate).format("DD/MM/YYYY") : ""
                        );
                      }}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          placeholder: `${t("mainForm.dateHolder")}`,
                        },
                        inputAdornment: {
                          position:
                            dimensions?.language === "he" ? "end" : "start",
                          sx: { p: 1 },
                        },
                      }}
                      format="DD\MM\YYYY"
                      disabled={switchChecked}
                    />
                  )}
                />
              </InputContainer>
              {/* {errors.date && <ErrorSpan>{errors.date.message}</ErrorSpan>} */}
            </LocalizationProvider>
          </DoubleInput>
          {ctx.publish && (
            <RegTripDiv $heb={dimensions.language === "he"}>
              <RegTripPara $heb={dimensions.language === "he"}>
                {t("mainForm.regularTravel")}
              </RegTripPara>
              <StyledSwitch
                $ride={ctx.selectOpt === "/rides"}
                checked={switchChecked}
                onChange={handleSwitchChange}
                color="default"
              />
            </RegTripDiv>
          )}
          {ctx.publish && switchChecked && (
            <RgTripDaysDiv $heb={dimensions.language === "he"}>
              {days.map((day, index) => (
                <DayBtn
                  key={index}
                  type="button"
                  $isSelected={selectedDays.includes(day)}
                  onClick={() => handleDayClick(day)}
                >
                  {t(`mainForm.${day.toLowerCase()}`)}
                </DayBtn>
              ))}
            </RgTripDaysDiv>
          )}
          {ctx.publish && (
            <>
              <InputContainer needIcon icon={Comments} comment>
                <Input
                  id="comments"
                  placeholder={t("mainForm.commentsHolder")}
                  $heb={dimensions?.language === "he"}
                  $comments={true}
                  {...register("comments")}
                />
              </InputContainer>
              {errors.comments && (
                <ErrorSpan>{errors.comments.message}</ErrorSpan>
              )}
            </>
          )}
        </AllInputs>
        <BtnDiv>
          <SubmitButton $driver={ctx.selectOpt === "/rides"} type="submit">
            {ctx.publish ? t("mainForm.addBtn") : t("mainForm.searchBtn")}
          </SubmitButton>
          <CancelButton
            $driver={ctx.selectOpt === "/rides"}
            type="button"
            onClick={handleCancel}
          >
            {t("mainForm.cancelBtn")}
          </CancelButton>
        </BtnDiv>
      </form>
    </MainFormContainer>
  );
};

export default MainForm;
