import { useContext } from "react";
import MainForm from "../../components/MainForm/MainForm";
import { StatesCtx } from "../../contexts/StatesProvider";
import ToggleForm from "../../components/ToggleForm/ToggleForm";
import { TopDiv } from "../Passenger/Passenger";
import TripCard from "../../components/TripCard/TripCard";

const RidesPage = () => {
  const ctx = useContext(StatesCtx);

  if (!ctx) {
    console.log("No Ctx!");
    return <p>No ctx!</p>;
  }

  let content;

  if (ctx.publish || ctx.search) {
    content = (
      <TopDiv>
        <MainForm fromOptions={ctx.fromData} toOptions={ctx.toData} />
        <TripCard />
      </TopDiv>
    );
  } else {
    content = (
      <TopDiv>
        <ToggleForm />
        <TripCard />
      </TopDiv>
    );
  }

  return <>{content}</>;
};

export default RidesPage;
