import { ReactComponent as HomeSVG } from "../../assets/Home.svg";
import styled from "styled-components";

const StyledHomeIcon = styled(HomeSVG)`
  fill: ${(props) => props.color};
`;

const HomeIcon: React.FC<{ color: string }> = ({ color }) => (
  <StyledHomeIcon color={color} />
);

export default HomeIcon;
