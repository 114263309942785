import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

import {
  MainFormContainer,
  SubmitButton,
} from "../../components/MainForm/MainForm";
import { FormValues, StyledInput, StyledLabel } from "../Register/Register";
import { useTranslation } from "react-i18next";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const dimensions = useContext(DimensionsCtx);
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const { register, control, handleSubmit } = form;

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await fetch("http://localhost:4000/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const { token, firstName, lastName, profilePic } =
          await response.json();

        localStorage.setItem("token", token);
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);
        localStorage.setItem("userPhoto", profilePic);
        navigate("/communities");
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <MainFormContainer
      $isMobile={dimensions.isMobile}
      $isTablet={dimensions.isTablet}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.email")}
        </StyledLabel>
        <StyledInput {...register("email", { required: true })} type="email" />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.password")}
        </StyledLabel>
        <StyledInput
          {...register("password", { required: true })}
          type="password"
        />
        <SubmitButton $driver>{t("registerLogin.login")}</SubmitButton>
      </form>
      <DevTool control={control} />
    </MainFormContainer>
  );
};

export default LoginPage;
