import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
`;

const StyledModal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 30;
`;

const ModalContent = styled.div`
  display: flex;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: ${slideDown} 300ms ease-out forwards;
`;

const BackDrop: React.FC<{ onClose: any }> = (props) => {
  return <StyledBackDrop onClick={props.onClose} />;
};

const ModalOverlay: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <StyledModal>
      <ModalContent>{props.children}</ModalContent>
    </StyledModal>
  );
};

const portalElement = document.getElementById("overlays");

const Modal: React.FC<{ children: React.ReactNode; onClose: Function }> = (
  props
) => {
  if (!portalElement) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
