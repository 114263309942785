import { ReactComponent as PassengerSVG } from "../../assets/Passenger.svg";
import styled from "styled-components";

const StyledPassengerIcon = styled(PassengerSVG)`
  fill: ${(props) => props.color};
`;

const PassengerIcon: React.FC<{ color: string }> = ({ color }) => (
  <StyledPassengerIcon color={color} />
);

export default PassengerIcon;
