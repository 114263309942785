import { useContext } from "react";
import { Outlet } from "react-router-dom";

import { StatesCtx } from "../../contexts/StatesProvider";
import NavContent from "../../components/Navigation/NavContent";
import Language from "../../components/Navigation/Language";
import TopNavBar from "../../components/Navigation/TopNavBar";

import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const FirstDiv = styled.div`
  width: 100%;
  padding: 20px 0px 20px;
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const SecDiv = styled.div`
  width: 100%;
  position: sticky;
  top: 74px;
  z-index: 999;
`;

const RootLayout = () => {
  const ctx = useContext(StatesCtx);

  if (!ctx) {
    console.error("No ctx!");
    return <p>No Ctx!</p>;
  }

  return (
    <StyledDiv>
      <FirstDiv>
        <TopNavBar />
      </FirstDiv>
      <SecDiv>
        <NavContent />
      </SecDiv>
      {ctx.showModal && <Language onClose={() => ctx.setShowModal(false)} />}

      <Outlet />
    </StyledDiv>
  );
};

export default RootLayout;
