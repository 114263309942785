// const MainDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-items: center;
// `;

const HomePage = () => {
  return (
    <div>
      <h1>Shuttle</h1>
      <h3>Make Carpool Fun Again!</h3>
    </div>
  );
};

export default HomePage;
