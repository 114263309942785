import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatesCtx } from "../../contexts/StatesProvider";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ExampleIcon,
  FeedbackIcon,
  LanguageIcon,
  MyCarIcon,
  MyProfileIcon,
  MyTripsIcon,
  SettingsIcon,
  UsersVerificationIcon,
} from "../icons/DrawersIcons/DrawersIcons";
import OpenDrawerSVG from "../../assets/Drawer/openDrawer.svg";
import DefaultProfilePic from "../../assets/Drawer/DefaultProfilePic.svg";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import styled from "styled-components";

const UserDetailesDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  gap: 8px;
`;

const PicDiv = styled.div`
  display: flex;
  width: fit-content;
  border: 1px solid #898989;
  border-radius: 14px;
`;

const PicImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 14px;
`;

const UserInfoDiv = styled.div``;

const UserNamePara = styled.p`
  color: black;
`;

const MainDrawer = () => {
  const ctx = useContext(StatesCtx);
  const dimension = useContext(DimensionsCtx);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!ctx || !dimension) {
    console.error("No ctx!");
    return <p>No Ctx!</p>;
  }

  const anchor = dimension.language === "en" ? "left" : "right";

  const userToken = localStorage.getItem("token") || null;
  const userFirstName = localStorage.getItem("firstName") || "Guest";
  const userLastName = localStorage.getItem("lastName") || "";
  const userPhoto = localStorage.getItem("userPhoto") || "";
  const defaultPhoto = DefaultProfilePic;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("userPhoto");

    navigate("/login");
  };

  const toggleDrawer = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  let content: React.ReactNode;

  if (!userToken && dimension.language === "en") {
    content = (
      <>
        <ListItem key={1} disablePadding>
          <ListItemButton onClick={() => navigate("/login")}>
            <ListItemIcon>{ExampleIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.login")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton onClick={() => navigate("/register")}>
            <ListItemIcon>{ExampleIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.signup")} />
          </ListItemButton>
        </ListItem>
      </>
    );
  } else if (userToken && dimension.language === "en") {
    content = (
      <>
        <ListItem key={3} disablePadding>
          <ListItemButton>
            <ListItemIcon>{MyProfileIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.myProfile")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={4} disablePadding>
          <ListItemButton>
            <ListItemIcon>{ExampleIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.myWallet")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={5} disablePadding>
          <ListItemButton>
            <ListItemIcon>{MyTripsIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.myTrips")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={6} disablePadding>
          <ListItemButton>
            <ListItemIcon>{MyCarIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.myCar")} />
          </ListItemButton>
        </ListItem>
      </>
    );
  } else if (!userToken && dimension.language === "he") {
    content = (
      <>
        <ListItem key={1} disablePadding>
          <ListItemButton onClick={() => navigate("/login")}>
            <ListItemText
              primary={t("drawer.login")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {ExampleIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton onClick={() => navigate("/register")}>
            <ListItemText
              primary={t("drawer.signup")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {ExampleIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </>
    );
  } else if (userToken && dimension.language === "he") {
    content = (
      <>
        <ListItem key={3} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.myProfile")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {MyProfileIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={4} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.myWallet")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {ExampleIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={5} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.myTrips")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {MyTripsIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={6} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.myCar")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {MyCarIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </>
    );
  } else {
    content = <p>Check userToken and dimension.language</p>;
    console.error("Check userToken and dimension.language");
  }

  let permanentContent: React.ReactNode;

  if (dimension.language === "en") {
    permanentContent = (
      <>
        <ListItem key={10} disablePadding>
          <ListItemButton onClick={() => ctx.setShowModal(true)}>
            <ListItemIcon>{LanguageIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.language")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={11} disablePadding>
          <ListItemButton>
            <ListItemIcon>{UsersVerificationIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.usersVerification")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={12} disablePadding>
          <ListItemButton>
            <ListItemIcon>{FeedbackIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.feedback")} />
          </ListItemButton>
        </ListItem>
        <ListItem key={13} disablePadding>
          <ListItemButton>
            <ListItemIcon>{SettingsIcon()}</ListItemIcon>
            <ListItemText primary={t("drawer.settings")} />
          </ListItemButton>
        </ListItem>
        {userToken ? (
          <ListItem key={20} disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>{ExampleIcon()}</ListItemIcon>
              <ListItemText primary={t("drawer.logout")} />
            </ListItemButton>
          </ListItem>
        ) : (
          ""
        )}
      </>
    );
  } else {
    permanentContent = (
      <>
        <ListItem key={10} disablePadding>
          <ListItemButton onClick={() => ctx.setShowModal(true)}>
            <ListItemText
              primary={t("drawer.language")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {LanguageIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={11} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.usersVerification")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {UsersVerificationIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={12} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.feedback")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {FeedbackIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={13} disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("drawer.settings")}
              sx={{ textAlign: "right" }}
            />
            <ListItemIcon
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {SettingsIcon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {userToken ? (
          <ListItem key={20} disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText
                primary={t("drawer.logout")}
                sx={{ textAlign: "right" }}
              />
              <ListItemIcon
                sx={{ display: "flex", flexDirection: "row-reverse" }}
              >
                {ExampleIcon()}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ) : (
          ""
        )}
      </>
    );
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>{content}</List>
      <Divider />
      <List>{permanentContent}</List>
    </Box>
  );

  return (
    <div>
      <img
        src={OpenDrawerSVG}
        alt="Open Drawer Icon"
        style={{ cursor: "pointer" }}
        onClick={() => toggleDrawer(true)}
      />
      <SwipeableDrawer
        anchor={anchor}
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <UserDetailesDiv>
          <PicDiv>
            <PicImg src={userToken ? userPhoto : defaultPhoto} alt="User" />
          </PicDiv>
          <UserInfoDiv>
            <UserNamePara>
              {userFirstName} {userLastName}
            </UserNamePara>
          </UserInfoDiv>
        </UserDetailesDiv>
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default MainDrawer;
