import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./pages/root/Root";
import ErrorPage from "./pages/error/Error";
import HomePage from "./pages/home/Home";
import "./App.css";
import SearchPage from "./pages/Search/Search";
import PublishPage from "./pages/Publish/Publish";
import { useEffect } from "react";
import Register from "./pages/Register/Register";
import RidesPage from "./pages/Driver/Rides";
import PassengerPage from "./pages/Passenger/Passenger";
import CommunitiesPage from "./pages/Communities/Communities";
import LoginPage from "./pages/Login/Login";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/communities",
        element: <CommunitiesPage />,
      },
      {
        path: "/rides",
        element: <RidesPage />,
        children: [
          {
            path: "search",
            element: <SearchPage />,
          },
          {
            path: "publish",
            element: <PublishPage />,
          },
        ],
      },
      {
        path: "/passenger",
        element: <PassengerPage />,
        children: [
          {
            path: "search",
            element: <SearchPage />,
          },
          {
            path: "publish",
            element: <PublishPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    document.title = "Shuttle";
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
