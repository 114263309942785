import { useContext, useState } from "react";

import { DimensionsCtx } from "../../contexts/DimensionsProvider";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export interface CityOption {
  id: number;
  country: string;
  city: string;
}

interface FromToProps {
  fromOptions: CityOption[];
  toOptions: CityOption[];
  role: string;
  onChange: (value: CityOption | null) => void;
  value: CityOption | null;
}

const FromTo: React.FC<FromToProps> = ({
  fromOptions,
  toOptions,
  role,
  onChange,
  value,
}) => {
  const dimensions = useContext(DimensionsCtx);

  const [open, setOpen] = useState<boolean>(false);

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const direction = dimensions.language === "he" ? "rtl" : "ltr";

  return (
    <Autocomplete
      disablePortal
      id="fromTo"
      open={open}
      onInputChange={() => {
        setOpen(true);
      }}
      onBlur={() => {
        setOpen(false);
      }}
      options={role === "From" || "מאיפה" ? fromOptions : toOptions}
      getOptionLabel={(option) => option.city}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={role}
          InputLabelProps={{
            ...params.InputLabelProps,
            style: {
              ...(dimensions.language === "he"
                ? { right: 0, transformOrigin: "top right" }
                : {}),
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
          sx={{
            // direction,
            "& .MuiOutlinedInput-root": {
              borderRadius: 20,
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      )}
      sx={{
        width: 1,
        "& .MuiInputBase-input": {
          textAlign: dimensions.language === "he" ? "end" : "start",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: 20,
        },
      }}
    />
  );
};

export default FromTo;
