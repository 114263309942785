import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import SearchIcon from "../../assets/Search.svg";
import PublishIcon from "../../assets/Publish.svg";
import { useContext } from "react";
import { StatesCtx } from "../../contexts/StatesProvider";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";

const ToggleDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 24px;
  align-items: center;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 28px;
  background-color: #e6e6e6;
`;

const StyledPara = styled.p`
  color: #898989;
`;

const StyledNavLink = styled(NavLink)<{ $driver: boolean }>`
  display: flex;
  height: 45px;
  padding-left: 28px;
  padding-right: 28px;
  text-decoration: none;
  align-items: center;
  border-radius: 28px;
  justify-content: center;

  &.active {
    background-color: ${(props) => (props.$driver ? "#6663F5" : "#FF9D9D")};
  }
`;

const Icon = styled.img<{ $active: boolean }>`
  display: flex;
  filter: ${(props) => (props.$active ? "invert(100%)" : "none")};
`;

const ToggleForm: React.FC = () => {
  const ctx = useContext(StatesCtx);
  const dimensions = useContext(DimensionsCtx);

  const { t } = useTranslation();

  if (!ctx || !dimensions) {
    console.error("No ctx!");
    return <p>No Ctx!</p>;
  }

  const checkDriver = () => {
    if (ctx.selectOpt === "/rides") {
      return true;
    } else if (ctx.selectOpt === "/passenger") {
      return false;
    } else if (ctx.selectOpt === "/communities") {
      return false;
    } else {
      console.error("Something went wrong");
      return false;
    }
  };

  const handleSearch = () => {
    ctx.setSearch(true);
    ctx.setPublish(false);
  };

  const handlePublish = () => {
    ctx.setPublish(true);
    ctx.setSearch(false);
  };

  let curTitle = ``;

  if (ctx.selectOpt === "/passenger" && ctx.publish) {
    curTitle = t("toggleForm.publishPassTitle");
  }

  if (ctx.selectOpt === "/passenger" && ctx.search) {
    curTitle = t("toggleForm.searchPassTitle");
  }

  if (ctx.selectOpt === "/rides" && ctx.publish) {
    curTitle = t("toggleForm.publishRideTitle");
  }

  if (ctx.selectOpt === "/rides" && ctx.search) {
    curTitle = t("toggleForm.searchRideTitle");
  }

  let content;

  if (dimensions?.language === "en") {
    content = (
      <StyledDiv>
        <StyledNavLink
          $driver={checkDriver()}
          to={checkDriver() ? "/rides/search" : "/passenger/search"}
          onClick={handleSearch}
        >
          <Icon src={SearchIcon} $active={ctx.search} />
        </StyledNavLink>
        <StyledNavLink
          $driver={checkDriver()}
          to={checkDriver() ? "/rides/publish" : "/passenger/publish"}
          onClick={handlePublish}
        >
          <Icon src={PublishIcon} $active={ctx.publish} />
        </StyledNavLink>
      </StyledDiv>
    );
  }

  if (dimensions?.language === "he") {
    content = (
      <StyledDiv>
        <StyledNavLink
          $driver={checkDriver()}
          to={checkDriver() ? "/rides/publish" : "/passenger/publish"}
          onClick={handlePublish}
        >
          <Icon src={PublishIcon} $active={ctx.publish} />
        </StyledNavLink>
        <StyledNavLink
          $driver={checkDriver()}
          to={checkDriver() ? "/rides/search" : "/passenger/search"}
          onClick={handleSearch}
        >
          <Icon src={SearchIcon} $active={ctx.search} />
        </StyledNavLink>
      </StyledDiv>
    );
  }

  return (
    <ToggleDiv>
      {content}
      <StyledPara>{curTitle}</StyledPara>
    </ToggleDiv>
  );
};

export default ToggleForm;
