import { useContext } from "react";

import { StatesCtx } from "../../contexts/StatesProvider";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import HomeIcon from "../icons/HomeIcon";
import PassengerIcon from "../icons/PassengerIcon";
import CarIcon from "../icons/CarIcon";
import { useTranslation } from "react-i18next";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";

const NavDiv = styled.div`
  display: flex;
  background: #f5f5f5;
  border-bottom: 1px solid #dbdbdb;
`;

const StyledUL = styled.ul<{ $desktop: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 100px;
  height: 100%;
  margin: auto;
  padding: 0px;
  justify-content: center;
  /* margin-left: ${(props) => (props.$desktop ? "450px" : "")};
  margin-right: ${(props) => (props.$desktop ? "450px" : "")}; */

  @media (max-width: 580px) {
    gap: 0;
    width: 100%;
  }
`;

const StyledLI = styled.li`
  width: 100%;
  margin: auto;
  list-style-type: none;
`;

const StyledNavLink = styled(NavLink)<{
  $driver: boolean;
  $community: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border-bottom: 3px solid;
    border-color: ${(props) => (props.$driver ? "#6663F5" : "#FF9D9D")};
    border-color: ${(props) => props.$community && "#FFC34E"};
  }

  &,
  & * {
    text-decoration: none !important;
  }
`;

const StyledDiv = styled.div<{ $language: string }>`
  display: flex;
  flex-direction: ${(props) =>
    props.$language === "en" ? "row-reverse" : "row"};
  width: 100%;
  padding-bottom: 12px;
  padding-top: 4px;
  gap: 8px;
  justify-content: center;
`;

const NavLabel = styled.label`
  color: black;
  font-size: 16px;
  font-weight: bold;
`;

const NavContent = () => {
  const ctx = useContext(StatesCtx);
  const dimensionsCtx = useContext(DimensionsCtx);
  const { t } = useTranslation();

  if (!ctx || !dimensionsCtx) {
    console.error("No ctx!");
    return <p>No Ctx!</p>;
  }

  const handleSelect = (path: string) => {
    if (path === "/passenger") {
      ctx.setSelectOpt("/passenger");
      ctx.setSearch(false);
      ctx.setPublish(false);
      return;
    } else if (path === "/rides") {
      ctx.setSelectOpt("/rides");
      ctx.setSearch(false);
      ctx.setPublish(false);
      return;
    } else if (path === "/communities") {
      ctx.setSelectOpt("/communities");
      ctx.setSearch(false);
      ctx.setPublish(false);
      return;
    } else if (path === "/") {
      ctx.setSelectOpt("/");
      ctx.setSearch(false);
      ctx.setPublish(false);
      return;
    }

    ctx.setPublish(false);
    ctx.setSearch(false);
  };

  const getIconColor = (state: string) => {
    if (ctx.selectOpt === "/passenger" && state === "/passenger") {
      return "#FF9D9D";
    } else if (ctx.selectOpt === "/rides" && state === "/rides") {
      return "#6663F5";
    } else if (ctx.selectOpt === "/communities" && state === "/communities") {
      return "#FFC34E";
    } else {
      return "black";
    }
  };

  return (
    <NavDiv>
      <StyledUL $desktop={!dimensionsCtx.isMobile && !dimensionsCtx.isTablet}>
        <StyledLI>
          <StyledNavLink
            $driver={ctx.selectOpt === "/rides"}
            $community
            to={"/communities"}
            onClick={() => handleSelect("/communities")}
          >
            <StyledDiv $language={dimensionsCtx.language}>
              <NavLabel>{t("nav.communitiesLabel")}</NavLabel>
              <HomeIcon color={getIconColor("/communities")} />
            </StyledDiv>
          </StyledNavLink>
        </StyledLI>
        <StyledLI>
          <StyledNavLink
            $driver={ctx.selectOpt === "/rides"}
            $community={false}
            to={"/passenger"}
            onClick={() => handleSelect("/passenger")}
          >
            <StyledDiv $language={dimensionsCtx.language}>
              <NavLabel>{t("nav.passengersLabel")}</NavLabel>
              <PassengerIcon color={getIconColor("/passenger")} />
            </StyledDiv>
          </StyledNavLink>
        </StyledLI>
        <StyledLI>
          <StyledNavLink
            $driver={ctx.selectOpt === "/rides"}
            $community={false}
            to={"/rides"}
            onClick={() => handleSelect("/rides")}
          >
            <StyledDiv $language={dimensionsCtx.language}>
              <NavLabel>{t("nav.ridesLabel")}</NavLabel>
              <CarIcon color={getIconColor("/rides")} />
            </StyledDiv>
          </StyledNavLink>
        </StyledLI>
      </StyledUL>
    </NavDiv>
  );
};

export default NavContent;
