import NavContent from "../../components/Navigation/NavContent";
import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: grey;
  text-align: center;
`;

const ErrorPage = () => {
  return (
    <>
      <NavContent />
      <ErrorContainer>
        <h1>An error occurred!</h1>
        <p>Could not find this page!</p>
      </ErrorContainer>
    </>
  );
};

export default ErrorPage;
