import { useContext } from "react";
import MainForm, {
  MainFormContainer,
} from "../../components/MainForm/MainForm";
import { StatesCtx } from "../../contexts/StatesProvider";
import ToggleForm from "../../components/ToggleForm/ToggleForm";
import styled from "styled-components";
import TripCard from "../../components/TripCard/TripCard";

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 36px;
`;

const PassengerPage = () => {
  const ctx = useContext(StatesCtx);

  if (!ctx) {
    console.log("No Ctx!");
    return <p>No ctx!</p>;
  }

  let content;

  if (ctx.publish || ctx.search) {
    content = (
      <TopDiv>
        <MainForm fromOptions={ctx.fromData} toOptions={ctx.toData} />
        <TripCard />
      </TopDiv>
    );
  } else {
    content = (
      <TopDiv>
        <ToggleForm />
        <TripCard />
      </TopDiv>
    );
  }

  return <>{content}</>;
};

export default PassengerPage;
