import { createContext, useEffect, useState } from "react";

type StatesContextType = {
  search: boolean;
  setSearch: React.Dispatch<React.SetStateAction<boolean>>;
  publish: boolean;
  setPublish: React.Dispatch<React.SetStateAction<boolean>>;
  selectOpt: string;
  setSelectOpt: React.Dispatch<React.SetStateAction<string>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fromData: any[];
  setFromData: React.Dispatch<React.SetStateAction<any[]>>;
  toData: any[];
  setToData: React.Dispatch<React.SetStateAction<any[]>>;
  fromCitiesFetchData: Function;
  toCitiesFetchData: Function;
};

export const StatesCtx = createContext<StatesContextType | null>(null);

export const StatesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [fromData, setFromData] = useState<any[]>([]);
  const [toData, setToData] = useState<any[]>([]);

  const [search, setSearch] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem("search") || "false")
  );
  const [publish, setPublish] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem("publish") || "false")
  );
  const [selectOpt, setSelectOpt] = useState<string>(
    () => localStorage.getItem("selectOpt") || ""
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("search", JSON.stringify(search));
    localStorage.setItem("publish", JSON.stringify(publish));
    localStorage.setItem("selectOpt", selectOpt);
  }, [search, publish, selectOpt]);

  const fromCitiesFetchData = async () => {
    await fetch("http://localhost:4000/places")
      .then((res) => res.json())
      .then((data) => setFromData(data))
      .catch((err) => {
        console.error(err);
      });
  };

  const toCitiesFetchData = async () => {
    await fetch("http://localhost:4000/places")
      .then((res) => res.json())
      .then((data) => setToData(data))
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <StatesCtx.Provider
      value={{
        search,
        setSearch,
        publish,
        setPublish,
        selectOpt,
        setSelectOpt,
        showModal,
        setShowModal,
        fromData,
        setFromData,
        toData,
        setToData,
        fromCitiesFetchData,
        toCitiesFetchData,
      }}
    >
      {children}
    </StatesCtx.Provider>
  );
};
