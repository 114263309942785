import React, { useContext, useState } from "react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

import { StatesCtx } from "../../contexts/StatesProvider";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import Modal from "../../UI/modal/Modal";

import styled from "styled-components";
import FalgILSVG from "../../assets/Drawer/il-flag.svg";
import FalgUSSVG from "../../assets/Drawer/us-flag.svg";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MidDiv = styled.div`
  display: flex;
  gap: 16px;
`;

const InnerDiv = styled.div`
  display: flex;
  gap: 4px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
`;

const StyledImg = styled.img`
  width: 20px;
  border-radius: 14px;
`;

const StyledBtn = styled.button`
  color: black;
  border: none;
`;

const Language: React.FC<{ onClose: Function }> = (props) => {
  const { t } = useTranslation();
  const dimensions = useContext(DimensionsCtx);
  const ctx = useContext(StatesCtx);

  if (!ctx) {
    console.log("No Ctx!");
    return null;
  }

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    dimensions.setLanguage(code);
    ctx.setShowModal(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <StyledDiv>
        <MidDiv>
          <InnerDiv>
            <StyledImg src={FalgUSSVG} />
            <StyledBtn onClick={() => handleLanguageChange("en")}>
              {t("nav.en")}
            </StyledBtn>
          </InnerDiv>
          <InnerDiv>
            <StyledImg src={FalgILSVG} />
            <StyledBtn onClick={() => handleLanguageChange("he")}>
              {t("nav.he")}
            </StyledBtn>
          </InnerDiv>
        </MidDiv>
        <StyledBtn onClick={() => props.onClose()}>Close</StyledBtn>
      </StyledDiv>
    </Modal>
  );
};

export default Language;
