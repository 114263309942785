import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

import styled from "styled-components";
import {
  MainFormContainer,
  SubmitButton,
} from "../../components/MainForm/MainForm";
import { useTranslation } from "react-i18next";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import React, { useContext, useState } from "react";

export const StyledInput = styled.input`
  display: flex;
  width: 100%;
  padding: 8px 17px 8px 10px;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: #fafafa;
`;

export const StyledLabel = styled.label<{ $heb: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$heb ? "flex-end" : "flex-start")};
`;

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  profilePic: File | null;
};

const Register = () => {
  const dimensions = useContext(DimensionsCtx);
  const { t } = useTranslation();

  const [confirmPassword, setConfirmPassword] = useState("");

  const form = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      profilePic: null,
    },
    mode: "onBlur",
  });

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const { register, control, handleSubmit } = form;

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("password", data.password);
    if (data.profilePic && data.profilePic.length > 0) {
      formData.append("profilePic", data.profilePic[0]);
    }

    if (data.password !== confirmPassword) {
      console.error("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch("http://localhost:4000/api/users/register", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("User registered!");
        // Handle success (e.g., redirect to login page)
      } else {
        console.error("Registration failed");
        // Handle failure (e.g., show error message)
      }
    } catch (error) {
      console.error("Registration error:", error);
      // Handle error (e.g., show error message)
    }
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <MainFormContainer
      $isMobile={dimensions.isMobile}
      $isTablet={dimensions.isTablet}
    >
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.firstName")}
        </StyledLabel>
        <StyledInput
          type="text"
          id="firstName"
          {...register("firstName", { required: "First Name is required" })}
        />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.lastName")}
        </StyledLabel>
        <StyledInput
          type="text"
          id="lastName"
          {...register("lastName", { required: "Last Name is required" })}
        />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.email")}
        </StyledLabel>
        <StyledInput
          type="email"
          id="email"
          {...register("email", { required: "Email is required" })}
        />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.password")}
        </StyledLabel>
        <StyledInput
          type="password"
          id="password"
          {...register("password", { required: "Password is required" })}
        />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.confirmPassword")}
        </StyledLabel>
        <StyledInput
          type="password"
          id="confirmPassword"
          onChange={handleConfirmPasswordChange}
        />
        <StyledLabel $heb={dimensions?.language === "he"}>
          {t("registerLogin.profilePic")}
        </StyledLabel>
        <StyledInput type="file" id="profilePic" {...register("profilePic")} />
        <SubmitButton $driver>{t("registerLogin.register")}</SubmitButton>
      </form>
      <DevTool control={control} />
    </MainFormContainer>
  );
};

export default Register;
