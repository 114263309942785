import { ReactComponent as CarSVG } from "../../assets/Car.svg";
import styled from "styled-components";

const StyledCarIcon = styled(CarSVG)`
  fill: ${(props) => props.color};
`;

const CarIcon: React.FC<{ color: string }> = ({ color }) => (
  <StyledCarIcon color={color} />
);

export default CarIcon;
