import styled from "styled-components";
import LatestTitle from "../../assets/LatestTitle.svg";
import MainDrawer from "./MainDrawer";

const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 16px 0px;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
`;

const TopNavBar = () => {
  return (
    <TopDiv>
      <img src={LatestTitle} />
      <MainDrawer />
    </TopDiv>
  );
};

export default TopNavBar;
