import styled from "styled-components";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import { useContext } from "react";

const DivContainer = styled.div<{ $comments: boolean }>`
  display: flex;
  height: ${(props) => (props.$comments ? "150px" : "36px")};
  padding: 8px 10px;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  background-color: #fafafa;
  border: 1px solid;
  border-color: #d9d9d9;
  border-style: "solid";
  border-radius: 20px;
`;

const InputContainer: React.FC<{
  children: any;
  needIcon: boolean;
  icon: string;
  comment: boolean;
}> = (props) => {
  const dimensions = useContext(DimensionsCtx);

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  let content;

  if (dimensions.language === "en") {
    content = (
      <DivContainer $comments={props.comment}>
        {props.needIcon ? <img src={props.icon} alt="Icon" /> : ""}
        {props.children}
      </DivContainer>
    );
  }

  if (dimensions.language === "he") {
    content = (
      <DivContainer $comments={props.comment}>
        {props.children}
        {props.needIcon ? <img src={props.icon} alt="Icon" /> : ""}
      </DivContainer>
    );
  }

  return <>{content}</>;
};

export default InputContainer;
