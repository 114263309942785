import SettingsSVG from "../../../assets/Drawer/settings.svg";
import FeedbackSVG from "../../../assets/Drawer/feedback.svg";
import LanguageSVG from "../../../assets/Drawer/language.svg";
import MyCarSVG from "../../../assets/Drawer/myCar.svg";
import MyTripsSVG from "../../../assets/Drawer/myTrips.svg";
import MyProfileSVG from "../../../assets/Drawer/myProfile.svg";
import UsersVerificationSVG from "../../../assets/Drawer/usersVerification.svg";
import ExampleSVG from "../../../assets/Drawer/example.svg";
import DefualtProfilePicSVG from "../../../assets/Drawer/defualtProfilePic.svg";

export const SettingsIcon = () => {
  return <img src={SettingsSVG} alt="Settings Icon" />;
};
export const FeedbackIcon = () => {
  return <img src={FeedbackSVG} alt="Feedback Icon" />;
};
export const LanguageIcon = () => {
  return <img src={LanguageSVG} alt="Language Icon" />;
};
export const MyCarIcon = () => {
  return <img src={MyCarSVG} alt="MyCar Icon" />;
};
export const MyTripsIcon = () => {
  return <img src={MyTripsSVG} alt="MyTrips Icon" />;
};
export const MyProfileIcon = () => {
  return <img src={MyProfileSVG} alt="MyProfile Icon" />;
};
export const UsersVerificationIcon = () => {
  return <img src={UsersVerificationSVG} alt="UsersVerification Icon" />;
};
export const ExampleIcon = () => {
  return <img src={ExampleSVG} alt="Example Icon" />;
};
