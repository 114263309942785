import { useContext, useEffect, useState } from "react";
import { DimensionsCtx } from "../../contexts/DimensionsProvider";
import { StatesCtx } from "../../contexts/StatesProvider";

import styled from "styled-components";
import { MainFormContainer } from "../MainForm/MainForm";

import ClockSVG from "../../assets/FormIcons/clock.svg";
import CalenderSVG from "../../assets/FormIcons/calendar.svg";
import DropdownSVG from "../../assets/TripCard/Dropdown.svg";
import CloseDropdownSVG from "../../assets/TripCard/CloseDropdown.svg";
import CarIcon from "../icons/CarIcon";
import PassengerIcon from "../icons/PassengerIcon";
import TripArrowIcon from "../icons/TripArrow";
import { useTranslation } from "react-i18next";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
`;

const CardDiv = styled(MainFormContainer)`
  width: 100%;
  max-width: 510px;

  @media (max-width: 620px) {
    width: 450px;
  }

  @media (max-width: 540px) {
    width: 380px;
  }

  @media (max-width: 460px) {
    width: 290px;
  }

  @media (max-width: 380px) {
    width: 240px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #898989;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex: 0 1 33.33%;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 1 33.33%;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-end;
  flex: 0 1 33.33%;
`;

const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ProfilePic = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px #898989 solid;
  border-radius: 50%;
`;

const DropdownArrowBtn = styled.button`
  border: none;
`;

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 12px;
  margin-bottom: 16px;

  p {
    color: #898989;
  }
`;

const DaysDiv = styled.div<{ $heb: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$heb ? "row-reverse" : "row")};
  width: 100%;
`;

const DayPara = styled.p<{ $isActive: boolean }>`
  display: flex;
  margin: auto;
  font-weight: ${(props) => (props.$isActive ? "bold" : "")};
  border-bottom: ${(props) => (props.$isActive ? "1px #898989 solid" : "")};
`;

interface Ride {
  from: string;
  to: string;
  date: string;
  time: string;
  userId: {
    firstName: string;
    lastName: string;
    profilePic: string;
  };
  tripType?: string;
  comments?: string;
  days?: string[];
}

interface Passenger extends Ride {}

type Trip = Ride | Passenger;

const TripCard = () => {
  const ctx = useContext(StatesCtx);
  const dimensions = useContext(DimensionsCtx);
  const { t } = useTranslation();

  const [trips, setTrips] = useState<Trip[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [dropdownOpenIndex, setDropdownOpenIndex] = useState<number | null>(
    null
  );

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const fetchRides = async () => {
      try {
        const response = await fetch("/api/rides");
        if (!response.ok) {
          throw new Error("Failed to fetch rides");
        }
        const data = await response.json();
        return data.map((ride: Ride) => ({ ...ride, tripType: "Ride" }));
      } catch (error) {
        console.error("Failed to fetch rides:", error);
        setError("Failed to fetch rides");
        return [];
      }
    };

    const fetchPassengers = async () => {
      try {
        const response = await fetch("/api/passengers");
        if (!response.ok) {
          throw new Error("Failed to fetch passengers");
        }
        const data = await response.json();
        return data.map((passenger: Passenger) => ({
          ...passenger,
          tripType: "Passenger",
        }));
      } catch (error) {
        console.error("Failed to fetch passengers:", error);
        setError("Failed to fetch passengers");
        return [];
      }
    };

    const fetchData = async () => {
      const rides = await fetchRides();
      const passengers = await fetchPassengers();
      const combinedTrips = [...rides, ...passengers].sort((a, b) => {
        const dateA =
          typeof a.date === "string"
            ? new Date(toComparableDate(a.date))
            : "Boolean";
        const dateB =
          typeof b.date === "string"
            ? new Date(toComparableDate(b.date))
            : "Boolean";

        if (dateA === "Boolean" && dateB === "Boolean") {
          return 0;
        } else if (dateA === "Boolean") {
          return 1;
        } else if (dateB === "Boolean") {
          return -1;
        } else {
          return dateA.getTime() - dateB.getTime();
        }
      });
      setTrips(combinedTrips);
    };

    fetchData();
  }, []);

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  if (!ctx) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  if (error) {
    return <div>Error loading trips: {error}</div>;
  }

  if (!dimensions) {
    console.error("No ctx");
    return <h1>No Ctx</h1>;
  }

  const toComparableDate = (dateString: string): string => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const convertDateFormat = (dateString: string | undefined | null) => {
    if (typeof dateString === "string") {
      const parts = dateString.split("/");
      return `${parts[0]}/${parts[1]}`;
    } else {
      return t("mainForm.regularTravel");
    }
  };

  const handleDropdown = (index: number) => {
    if (dropdownOpenIndex === index) {
      setDropdownOpenIndex(null);
    } else {
      setDropdownOpenIndex(index);
    }
  };

  const ClockIcon = () => {
    return <img src={ClockSVG} alt="Clock Icon" />;
  };
  const CalenderIcon = () => {
    return <img src={CalenderSVG} alt="Calender Icon" />;
  };
  const DropdownIcon = () => {
    return <img src={DropdownSVG} alt="Dropdown Icon" />;
  };
  const CloseDropdownIcon = () => {
    return <img src={CloseDropdownSVG} alt="CloseDropdown Icon" />;
  };

  return (
    <MainDiv>
      {trips.map((trip, index) => (
        <CardDiv
          $isMobile={dimensions.isMobile}
          $isTablet={dimensions.isTablet}
          key={index}
        >
          <StyledDiv>
            <LeftDiv>
              {trip.time} {ClockIcon()}
            </LeftDiv>
            <CenterDiv>
              {trip.tripType === "Passenger" ? (
                <PassengerIcon color={"#FF9D9D"} />
              ) : (
                <CarIcon color={"#6663F5"} />
              )}
            </CenterDiv>
            <RightDiv>
              {convertDateFormat(trip.date)} {CalenderIcon()}
            </RightDiv>
          </StyledDiv>
          <StyledDiv>
            <LeftDiv>{trip.to}</LeftDiv>
            <CenterDiv>
              {
                <TripArrowIcon
                  color={trip.tripType === "Passenger" ? "#FF9D9D" : "#6663F5"}
                />
              }
            </CenterDiv>
            <RightDiv>{trip.from}</RightDiv>
          </StyledDiv>
          <StyledDiv>
            <DropdownArrowBtn
              type="button"
              onClick={() => handleDropdown(index)}
            >
              {dropdownOpenIndex === index
                ? CloseDropdownIcon()
                : DropdownIcon()}
            </DropdownArrowBtn>
            <ProfileDiv>
              {trip.userId.firstName} {trip.userId.lastName}
              <ProfilePic src={trip.userId.profilePic} alt="ProfilePic" />
            </ProfileDiv>
          </StyledDiv>
          {dropdownOpenIndex === index && (
            <DropdownDiv>
              <p>{trip.comments}</p>
              <DaysDiv $heb={dimensions.language === "he"}>
                {weekDays.map((dayKey, idx) => {
                  if (trip.days) {
                    return (
                      <DayPara key={idx} $isActive={trip.days.includes(dayKey)}>
                        {t(`mainForm.${dayKey.toLowerCase()}`)}
                      </DayPara>
                    );
                  } else {
                    return <p>No trip.days</p>;
                  }
                })}
              </DaysDiv>
            </DropdownDiv>
          )}
        </CardDiv>
      ))}
    </MainDiv>
  );
};

export default TripCard;
