import { ReactComponent as TripArrowSVG } from "../../assets/TripCard/TripArrow.svg";
import styled from "styled-components";

const StyledTripArrowIcon = styled(TripArrowSVG)<{
  color: string;
}>`
  fill: ${(props) => props.color};

  @media (max-width: 460px) {
    width: 70px;
  }

  @media (max-width: 380px) {
    width: 60px;
  }
`;

const TripArrowIcon: React.FC<{ color: string }> = ({ color }) => (
  <StyledTripArrowIcon color={color} />
);

export default TripArrowIcon;
